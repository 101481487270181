
import * as React from "react";
import LightboxImplementation from 'react-images';

export interface LightboxProps {
  images: any[];
  imageIndex?: number;
}

export interface LightboxState {
  lightboxOpen: boolean;
  currentImage: number;
}

export default class Lightbox extends React.Component<LightboxProps, LightboxState> {
  constructor(props?: LightboxProps) {
    super(props)
    this.state = {
      lightboxOpen: false,
      currentImage: 0
    }
  }
  componentWillReceiveProps(props?: LightboxProps) {
    if (typeof(props.imageIndex) !== "undefined" && props.imageIndex >= 0) {
        this.openLightbox(props.imageIndex);
    }
  }
  render() {
    return <LightboxImplementation
      currentImage={this.state.currentImage}
      images={this.props.images}
      isOpen={this.state.lightboxOpen}
      onClickImage={this.handleClickImage}
      onClickNext={this.gotoNext}
      onClickPrev={this.gotoPrevious}
      onClickThumbnail={this.gotoImage}
      onClose={this.closeLightbox}
    />
  }

  openLightbox = (index: number) => {
    this.setState({
      currentImage: index,
      lightboxOpen: true,
    });
  }
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxOpen: false,
    });
  }
  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  gotoImage = (index: number) => {
    this.setState({
      currentImage: index,
    });
  }
  handleClickImage = () => {
    if (this.state.currentImage === this.props.images.length - 1) return;

    this.gotoNext();
  }
}


