import axios, { AxiosResponse } from "axios";

import { IApiClient, IMaybeResult, ICustomSizePriceResponse } from "./interfaces";
import { Shop } from "stores";

export * from "./interfaces";

export class ApiClient implements IApiClient {
  apiUrl: string;
  shopId: number;
  constructor(apiUrl: string, shopId: number | string) {
    this.apiUrl = apiUrl;

    if (typeof (shopId) === "string") {
      this.shopId = parseInt(shopId);
    } else {
      this.shopId = shopId;
    }
  }
  getShop(): Promise<IMaybeResult<Shop>> {
    return axios({
      method: "POST",
      url: `${this.apiUrl}/shop/info`,
      data: {
        ShopId: this.shopId
      }
    }).then(
      (response: AxiosResponse) => ({
        result: Shop.from(response.data)
      }),
      handleError<Shop>()
    );
  } 
  getCustomSizePrice(materialId: number, height: number, width: number): Promise<IMaybeResult<ICustomSizePriceResponse>> {
    return axios({
      method: "POST",
      url: `${this.apiUrl}/material/customSize`,
      data: {
        MaterialId: materialId,
        Height: height,
        Width: width
      }
    }).then(
      (response: AxiosResponse) => ({
        result: response.data
      }),
      handleError<ICustomSizePriceResponse>()
    )
  }
}
export function handleError<T>(): (data: any) => IMaybeResult<T> {
  return (data) => ({
    result: undefined
  });
}
