import * as React from 'react';

import { GatsbyPageProps } from './gatsby-types';
import {  UIStore, ShopStore } from 'stores';

export interface PrintShopGalleryPageProps<C = any,D = any> extends GatsbyPageProps<C, D> {
  uiStore?: UIStore;
  shopStore?: ShopStore;
}

export class PrintShopGalleryPageComponent<D = any, C = any, S = any> extends React.Component<PrintShopGalleryPageProps<D, C>, S>
{

}
