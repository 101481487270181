import * as React from "react";

import "./index.less"
import Breadcrumbs from 'components/breadcrumbs';

export default class Layout extends React.Component<any> {
  render() {
    const style: React.CSSProperties = {
      minHeight: "100vh",
      position: "relative"
    }
    return (
      <>
        <main className="mw8 center h100 pa3 pa0-l" style={style}>
           <Breadcrumbs location={this.props.location} />
          {this.props.children}
        </main>
      </>
    )
  }
  
}
