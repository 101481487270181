import * as React from "react";
import { graphql, navigate } from "gatsby";

import { GalleryPageCollection } from 'components';
import { SquidexGalleryOverviewPage, SquidexGalleryItemConnection } from 'generated/gatsbyApiTypes';
import { PrintShopGalleryPageComponent, getLanguageValue} from 'utils';

export interface QueryData
{
  me: SquidexGalleryOverviewPage;
  galleries: SquidexGalleryItemConnection;
}

export default class GalleryOverviewPage extends PrintShopGalleryPageComponent<QueryData> {
  render() {
    const pageTitle = getLanguageValue(this.props.data.me.title);
    const pageText = getLanguageValue(this.props.data.me.text).html;
    return (
      <>
        <h1>{pageTitle}</h1>
        <div className="markdown" dangerouslySetInnerHTML={{ __html: pageText }} />
        <GalleryPageCollection nodes={this.props.data.galleries.nodes} navigate={navigate} />
      </>
    )
  }
  navigate = (link: string) => {
    if (!!link) {
      navigate(link);
    }
  }
}

export const query = graphql`
{
  me: squidexGalleryOverviewPage(identity: {eq: "galleryOverview"}) {
    title {
      de
      nl
      en
    }
    text {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
    btnText {
      de
      nl
      en
    }
  }
  galleries: allSquidexGallery(sort: {order:DESC, fields: priority___iv}) {
    nodes {
      name {
        de
        nl
        en 
      }
      slug {
        iv
      }
      headerImage {
        childImageSharp {
          fluid(maxWidth: 1600) { 
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
}
`
