import React from "react"
import ErrorHandler from "./src/components/errorHandler";
import Layout from "./src/components/layout";
import Modal from "./src/components/modal";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element , props}) => {
    return (
        <ErrorHandler>
        <Modal />
        <Layout {...props}>{element}</Layout>
        </ErrorHandler>
    )
}
