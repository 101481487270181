import * as React from "react";
import { graphql, navigate } from "gatsby";
import { inject } from "mobx-react";

import { Gallery } from "components";
import { PrintShopGalleryPageComponent, getLanguageValue, getFluidImageSharp } from "utils";
import { SquidexGallery } from 'generated/gatsbyApiTypes';

export interface QueryData {
  squidexGallery: SquidexGallery;
}

@inject("shopStore")
export default class GalleryPage extends PrintShopGalleryPageComponent<QueryData> {
  render() {
    const name = getLanguageValue(this.props.data.squidexGallery.name);
    const intro = getLanguageValue(this.props.data.squidexGallery.headertext);
    const header = getFluidImageSharp(this.props.data.squidexGallery.headerImage);
    const galleryId = this.props.data.squidexGallery.galleryId ? this.props.data.squidexGallery.galleryId.iv : undefined;
    const gallery = (galleryId && galleryId !== -1) ? this.props.shopStore.current.imageGalleries.filter((g) => g.id === galleryId)[0] : undefined;
    return (
      <>
        <h1>{name}</h1>
        <p className="pa0">{intro}</p>
        <Gallery gallery={gallery} nodes={this.props.data.squidexGallery.galleryItems} navigate={navigate} />
      </>
    )
  }
}

export const query = graphql`
query($id: String!) {
  squidexGallery(id: {eq: $id}) {
    name {
      de
      nl
      en 
    }
    headertext {
      de
      nl
      en
    }
    headerImage {
      childImageSharp {
        fluid(maxWidth: 700, maxHeight: 400, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    galleryId {
      iv
    }
    galleryItems {
      name {
        iv
      }
      description {
        iv
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
          original {
            src
            width
            height
          }
        }
      }
    }
  }
}
`
