import { SquidexAsset } from 'generated/gatsbyApiTypes';
import { FluidObject, FixedObject } from 'gatsby-image';

interface SquidexMultiLanguage<A,B,C> {
  de?: A;
  nl?: B;
  en?: C;
}

export function getLanguageValue<A,B,C>(obj: SquidexMultiLanguage<A,B,C>, language:string = process.env.GATSBY_LANGUAGE): A {
  return obj ? (obj as any)[language] as A : undefined;
}

export function getFluidImageSharp(obj: SquidexAsset)
{
  if (obj && obj.childImageSharp && obj.childImageSharp.fluid) {
    return obj.childImageSharp.fluid as FluidObject;
  }
  return undefined;
}

export function getFixedImageSharp(obj: SquidexAsset)
{
  if (obj && obj.childImageSharp && obj.childImageSharp.fixed) {
    return obj.childImageSharp.fixed as FixedObject;
  }
  return undefined;
}

