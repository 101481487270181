import React from "react"
import { Provider } from "mobx-react"

import { ApiClient } from "./src/side-effects"
import { UIStore, ShopStore} from "./src/stores"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    const apiClient = new ApiClient(process.env.GATSBY_API_URL, process.env.GATSBY_SHOP_ID);
    const shopStore = new ShopStore(apiClient);
    const uiStore = new UIStore()
    const stores = {
        shopStore,
        uiStore
    }
    return (
        <Provider {...stores}>{element}</Provider>
    )
}
