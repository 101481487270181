import { action, observable, set } from "mobx";

import { IApiClient } from "../../side-effects";

import { Shop, Format } from "./shop";
import { IStore } from "../interfaces";
import autoStore from "../autoStore";

export class ShopStore implements IStore {
  @observable public current: Shop;
  @observable public fetching: boolean;
  public key = "ShopStore";
  public version = process.env.GATSBY_BUILD;
  public customFormatScales: number[];
  private apiClient: IApiClient;
  constructor(apiClient: IApiClient) {
    this.current = new Shop();
    this.apiClient = apiClient;
    this.customFormatScales = process.env.GATSBY_CUSTOM_SIZE_FACTOR.split(",").map(f => Number.parseFloat(f));
    autoStore(this);
    if (this.current.materials.length == 0) {
      this.load();
    }
  }
  public calcDeliverCost(subTotal: number) {
    return subTotal > this.current.deliverTreshhold ? 0 : this.current.deliverCost;
  }
  public getMaterial(id: number) {
    return this.current.materials.find((m) => m.id == id);
  }
  public getMaterials(ids: number[]) {
    return this.current.materials.filter((m) => ids.indexOf(m.id) !== -1);
  }
  public generateCustomFormat(materialId: number, width: number, height: number) {
    this.fetching = true;
    return this.apiClient.getCustomSizePrice(materialId, height, width)
      .then(
        ({ result }) => {
          this.fetching = false;
          const format = new Format();
          format.price = result.price;
          format.height = height;
          format.width = width;
          return format;
        }
      )
  }
  @action public clear() {
    this.current = new Shop();
  }
  @action public load() {
    this.fetching = true;
    return this.apiClient.getShop()
      .then(({ result }) => {
        if (!result) {
          this.current = new Shop();
        } else {
          this.current = result;
        }
        this.fetching = false;
        return result;
      });
  }
  public loadFromStorage(store: ShopStore) {
    this.current = store.current;
  }
}
