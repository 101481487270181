
import * as React from "react";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { IPromiseBasedObservable } from "mobx-utils";

import { Material, Format, ImageGallery } from 'stores';
import { SquidexGalleryItem } from 'generated/gatsbyApiTypes';

import GalleryItem from './galleryItem';

export interface GalleryItemsContainerProps {
  nodes: SquidexGalleryItem[]
  orderButtonText: string;
  onMaterialText: string;
  clickToEnlargeText: string;
  forPriceText: string;
  gallery?: ImageGallery;
  getMaterial: (item: SquidexGalleryItem) => Material;
  getFormat: (item: SquidexGalleryItem, material?: Material) => IPromiseBasedObservable<Format>;
  onImageClick?: (image: any) => void;
  onOrderClick?: (node: any, material?: Material, format?: Format) => void;
}


export default class GalleryItemsContainer extends React.Component<GalleryItemsContainerProps> {

  render() {
    return (
      <ResponsiveMasonry columnsCountBreakPoints={{480: 2, 800: 3, 1000: 4}}>
        <Masonry gutter="2em">
          {
            this.props.nodes.map((node, i) => {
              return (
                <GalleryItem
                  node={node}
                  forPriceText={this.props.forPriceText}
                  gallery={this.props.gallery}
                  onImageClick={this.onImageClick}
                  onMaterialText={this.props.onMaterialText}
                  onOrderClick={this.onOrderClick}
                  orderButtonText={this.props.orderButtonText}
                  clickToEnlargeText={this.props.clickToEnlargeText}
                  key={`galleryitem-${i}`}
                />
              )
            })
          }
        </Masonry>
      </ResponsiveMasonry>
    )
  }
  onImageClick = (image: any) => {
    if (this.props.onImageClick) {
      this.props.onImageClick(image);
    }
  }
  onOrderClick = (node: any, material?: Material, format?: Format) => {
    if (this.props.onOrderClick) {
      this.props.onOrderClick(node, material, format);
    }
  }
}


