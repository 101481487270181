import { action, observable } from "mobx";


export class UIStore  {
  constructor() {
  }

  @observable public modalActive: boolean;
  @observable public modalText: string;
  @observable public modalHeader: string;

  @action public showModal(header: string, text: string) {
    this.modalActive = true;
    this.modalHeader = header;
    this.modalText = text;
  }
  @action public hideModal() {
    this.modalActive = false;
    this.modalText = "";
    this.modalHeader = "";
  }
}
