import { StaticQuery, graphql } from "gatsby"
import * as React from "react";
import { inject, Observer } from "mobx-react";
import { fromPromise } from 'mobx-utils';

import { ShopStore, Material, Format, ImageGallery } from "stores";
import { Lightbox } from 'components';
import { SquidexGalleryItem, SquidexTexts, SquidexConfig } from 'generated/gatsbyApiTypes';

import GalleryItemsContainer from './galleryItemsContainer';
import { getLanguageValue } from 'utils';

export interface GalleryProps {
  nodes: SquidexGalleryItem[]
  materialId?: number;
  gallery?: ImageGallery;
  shopStore?: ShopStore;
  navigate: (route: string) => void;
}



export interface GalleryState {
  currentImage: number;
}

@inject("shopStore")
export default class Gallery extends React.Component<GalleryProps, GalleryState> {
  private shopSlug: string;
  constructor(props: GalleryProps) {
    super(props);
    this.state = {
      currentImage: undefined
    }
  }
  render() {
    return <StaticQuery
      query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          btnGalleryitemOrder {
            de
            nl
            en
          }
          txtOnMaterial {
            de
            nl
            en
          }
          txtForPrice {
            de
            nl
            en
          }
          txtClickToEnlarge {
            de
            nl
            en
          }
        }
        squidexConfig(identity: {eq: "config"}) {
          pageShopSlug {
            iv
          }
        }
      }`}
      render={
        (data: {squidexTexts: SquidexTexts, squidexConfig: SquidexConfig}) => {
          const images = this.props.nodes.map((node) => node.image && node.image.childImageSharp ? node.image.childImageSharp.fluid : undefined).filter(i => !!i);
          this.shopSlug = data.squidexConfig.pageShopSlug.iv.toLowerCase();
          return (
            <Observer>{() => (
              <>
                <GalleryItemsContainer
                  forPriceText={getLanguageValue(data.squidexTexts.txtForPrice)}
                  getMaterial={this.getMaterial}
                  getFormat={this.getFormat}
                  nodes={this.props.nodes}
                  onOrderClick={this.handleOrder}
                  onMaterialText={getLanguageValue(data.squidexTexts.txtOnMaterial)}
                  orderButtonText={getLanguageValue(data.squidexTexts.btnGalleryitemOrder)}
                  clickToEnlargeText={getLanguageValue(data.squidexTexts.txtClickToEnlarge)}
                  onImageClick={this.handleItemImageClick}
                  gallery={this.props.gallery}
                />
                <Lightbox images={images} imageIndex={this.state.currentImage} />
              </>
            )}</Observer>
          )
        }}
    />
  }
  handleItemImageClick = (node: SquidexGalleryItem,) => {
    // const originalImg = node.image.childImageSharp.original;
    // const imgSrc =  window.location.origin + originalImg.src;
    // window.open(imgSrc);
    const index = this.props.nodes.indexOf(node);
    this.setState({ currentImage: index });
  }
  getMaterial = () => {
    if (this.props.materialId) {
      var pageMaterial = this.props.shopStore.getMaterial(this.props.materialId);
      if (pageMaterial && !pageMaterial.customSizes) {
        return pageMaterial;
      }
    }
    const materials = this.props.shopStore.current.materials;
    if (materials.length == 0) {
      return undefined;
    }
    const index = Math.floor(Math.random() * materials.length);
    return materials[index];
  }
  getFormat = (node: any, material?: Material) => {
    if (!material) {
      return undefined;
    }
    const image = node.image && node.image.childImageSharp ? node.image.childImageSharp.fluid : undefined;
    const aspectRatio = image ? image.aspectRatio : 1;
    if (material.customSizes) {
      const scale = this.props.shopStore.customFormatScales[Math.floor(Math.random() * this.props.shopStore.customFormatScales.length)]
      const width = Math.floor(scale * aspectRatio);
      const height = Math.floor(scale);

      return fromPromise<Format>(this.props.shopStore.generateCustomFormat(material.id, width, height));
    }
    return fromPromise<Format>(new Promise((res, rej) => res(material.formats.reduce((best, next) => {
      if (!best || Math.abs(aspectRatio - best.width / best.height) > Math.abs(aspectRatio - next.width / next.height)) {
        return next;
      } else {
        return best;
      }
    }))));
  }
  handleOrder = (galleryItem: SquidexGalleryItem, material?: Material, format?: Format) => {
    if (window) {
      let url = process.env.GATSBY_SHOP_URL + "/Preload.aspx";
      const imgSrc =  galleryItem.image.childImageSharp.original.src;
      url += "?img=" + imgSrc;
      if (material && material.id) {
        url += "&MaterialId=" + material.id;
        if (format && format.id) {
          url += "&FormatId=" + format.id;
        }
      }
      window.top.location = url as any;
    }
  }

}


