import * as React from "react";
import Img from "gatsby-image";

import { SquidexGallery } from 'generated/gatsbyApiTypes';
import { getFluidImageSharp, getLanguageValue } from 'utils';

export interface GalleryPageCollectionItemProps {
  node: SquidexGallery;
  onClick: (node: SquidexGallery) => void;
}

export default class GalleryPageCollectionItem extends React.Component<GalleryPageCollectionItemProps> {
  render() {
    const node = this.props.node;
    const img = node ? getFluidImageSharp(node.headerImage) : undefined;
    if (!img) {
      return null;
    }
    const title = node ? getLanguageValue(node.name) : "";
    return (
      <div className="inline-flex flex-column w-49 w-23-l tc mb3 dim pointer mr-2thirdpct-l mr-1pct">
        <a onClick={this.handleClick} className="ba b--black-30 shadow-1">
          <Img fluid={img} alt={title} style={{"height": "150px"}} />        
          <h4 className="ma0 mb2 pt2 bt b--black-30">{title}</h4>
        </a>
      </div>
    )
  }
  handleClick = () => {
    this.props.onClick(this.props.node);
  }
}


