export class Shop {

    static from(data: any) {
        const shop = new Shop();
        shop.deliverCost = data.deliverCost;
        shop.deliverTreshhold = data.deliverTreshhold;
        shop.materials = data.materials.map(Material.from);
        shop.upsells = data.upsells.map(Upsell.from);
        shop.paymentMethods = data.paymentMethods.map(PaymentMethod.from);
        shop.imageGalleries = data.imageGalleries ? data.imageGalleries : [];
        return shop;
    }
    constructor() {
        this.deliverCost = -1;
        this.deliverTreshhold = 0;
        this.materials = [];
        this.upsells = [];
        this.paymentMethods = [];
        this.imageGalleries = [];
    }

    public deliverTreshhold: number;
    public deliverCost: number;
    public materials: Material[];
    public upsells: Upsell[];
    public paymentMethods: PaymentMethod[];
    public imageGalleries: ImageGallery[];
}

export class Material {
    static from(data: any) {
        const material = new Material();
        material.id = data.id;
        material.name = data.name;
        material.displayPriority = data.diplayPriority;
        material.woodLength = data.woodLength;
        material.customSizes = data.customSizes;
        material.formats = data.formats.map(Format.from);
        material.options = data.options.map(Option.from);
        return material;
    }
    public id: number;
    public name: string;
    public displayPriority: number;
    public woodLength: number;
    public customSizes: boolean;
    public formats: Format[];
    public options: Option[];
}

export class Format {
    static from(data: any) {
        const format = new Format();
        format.id = data.id;
        format.price = data.price;
        format.name = data.name;
        format.width = data.width;
        format.height = data.height;
        format.houding = data.houding;
        format.diplayPriority = data.displayPriority;
        return format;
    }
    public id: number;
    public price: number;
    public name: string;
    public width: number;
    public height: number;
    public houding: string;
    public diplayPriority: number;
}

export class Option {
    static from(data: any) {
        const option = new Option();
        option.id = data.id;
        option.name = data.name;
        option.price = data.price;
        option.filter = data.filter;
        option.priceType = data.priceType;
        option.displayPriority = data.diplayPriority; 
        return option;
    }
    public id: number;
    public name: string;
    public price: number;
    public filter: string;
    public priceType: string;
    public displayPriority: number;
}

export class Upsell {
    static from(data: any) {
        const upsell = new Upsell();
        upsell.id = data.id;
        upsell.name = data.name;
        upsell.price = data.price;
        upsell.filter = data.filter;
        upsell.priceType = data.priceType;
        upsell.displayPriority = data.displayPriority;
        return upsell;
    }
    public id: number;
    public name: string;
    public price: number;
    public priceType: string;
    public filter: string;
    public displayPriority: number;
}

export class PaymentMethod {
  public code: string;
  static from(data: any) {
    const result = new PaymentMethod();
    result.code = data.code;
    return result;
  }
}

export class ImageGallery {
  public id: number;
  public name: string;
  public price: number;
}
