import { observer, inject } from "mobx-react"
import * as React from "react";

import { UIStore } from "stores";
import { Button } from "components";

import "./modal.less";
import ReactDOM from 'react-dom';

@inject("uiStore")
@observer
export default class Modal extends React.Component<any> {
  el: HTMLElement;
  constructor(props: any) {
    super(props);
    if (typeof(document) !== "undefined") {
      this.el = document.getElementById("gbp_alert");
    }
  }
  render() {
    if (typeof(document) === "undefined") {
      return null;
    }
    const store: UIStore = this.props.uiStore;
    const modalCs = store.modalActive ? "active modal" : "modal"
    return ReactDOM.createPortal(
      <div className={modalCs}>
        <div className="backdrop" />
        <div className="modal content bg-near-white w5">
          <div className="modal header pa3 f4 w100">
            {store.modalHeader}
          </div>
          <div className="modal body pa3 w100">
            {store.modalText}
          </div>
          <div className="modal footer pa3 w100 tc">
            <Button color="theme" label="Ok" onClick={this.closeModal} />
          </div>
        </div>
      </div>
     , this.el);
  }
  closeModal = () => {
    const store: UIStore = this.props.uiStore;
    store.hideModal();
  }
}


